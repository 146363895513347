import Console.history
import kotlinx.browser.window
import kotlin.js.Date

private fun listFiles(argv: List<String>): List<String> {
    return if (argv.isEmpty()) {
        files.map { it.name }
    } else files.filter { it.name.indexOf(argv[0]) == 0 || it.name.indexOf(" ") == 0 }.map { it.name }
}

val commands = mapOf(
    "help" to Console.Help,
    "clear" to object : Command {
        override val help = "clear the terminal screen"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) = Console.clear()
    },
    "cat" to object : Command {
        override val help = "usage: cat [file ...]"
        override fun complete(argv: List<String>): List<String> = listFiles(argv)
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            if (argv.isNotEmpty()) {
                files.forEach {
                    if (it.name == argv[0]) {
                        print("<p>${it.content}</p>")
                        return
                    }
                }
                print("${argv[0]}: no such file")
            }
            else {
                print(help)
            }
        }
    },
    "history" to object : Command {
        override val help = "show the command history"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            history.forEachIndexed { i, it ->
                if (i > 0) {
                    print(br)
                }
                print("$i $it")
            }
        }
    },
    "head" to object : Command {
        override val help = "usage: head [file ...]"
        override fun complete(argv: List<String>): List<String> = listFiles(argv)
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            if (argv.isNotEmpty()) {
                val n = if (argv.size > 1) argv[1].toIntOrNull() ?: 10 else 10
                files.forEach { file ->
                    if (file.name == argv[0]) {
                        file.content.lines().take(n).forEach { print(it) }
                        return
                    }
                }
                print("${argv[0]}: no such file")
            }
            else {
                print(help)
            }
        }
    },
    "tail" to object : Command {
        override val help = "usage: tail [file ...]"
        override fun complete(argv: List<String>): List<String> = listFiles(argv)
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            if (argv.isNotEmpty()) {
                val n = if (argv.size > 1) argv[1].toIntOrNull() ?: 10 else 10
                files.forEach {
                    if (it.name == argv[0]) {
                        it.content.lines().takeLast(n).forEach { print(it) }
                        return
                    }
                }
                print("${argv[0]}: no such file")
            }
            else {
                print(help)
            }
        }
    },
    "ls" to object : Command {
        override val help = "usage: ls (no additional flags supported)"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            if (argv.isEmpty()) {
                files.forEachIndexed { i, it ->
                    if (argv.isEmpty() || argv[0] == it.name) {
                        if (i > 0) {
                            print(space)
                        }
                        print(it.name)
                    }
                }
            }
            else {
                print(help)
            }
        }
    },
    "tree" to object : Command {
        override val help = "list contents of directories in a tree-like format"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            val sb = StringBuilder()
            sb.append(".$br")
            files.forEachIndexed { index, file ->
                if (index == files.size - 1) {
                    sb.append("└── ${file.name}$br")
                } else {
                    sb.append("├── ${file.name}$br")
                }
            }
//            sb.append("$br 1 directory, ${files.size} files")
            print(sb.toString())
        }
    },
    "cd" to object : Command {
        override val help = "change the current working directory"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            if (argv.isNotEmpty()) {
                print("cd: cannot change directory: read-only file system")
            }
            else {
                print(help)
            }
        }
    },
    "jobs" to object : Command {
        override val help = "show the active jobs in shell"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            print(jobs.content)
        }
    },
    "mkdir" to object : Command {
        override val help = "usage: mkdir [directory ...]"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            if (argv.isNotEmpty()) {
                print("mkdir: cannot create directory '${argv[0]}': read-only file system")
            }
            else {
                print(help)
            }
        }
    },
    "date" to object : Command {
        override val help = "print the current date and time"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            print(Date().toString())
        }
    },
    "echo" to object : Command {
        override val help = "usage: echo [text ...]"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            print(argv.joinToString(" "))
        }
    },
    "printenv" to object : Command {
        override val help = "print all environment variables"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            env.forEach { (key, value) -> print("$key=$value$br") }
        }
    },
    "pwd" to object : Command {
        override val help = "print the current working directory"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            print("https://$host/")
        }
    },
    "whoami" to object : Command {
        override val help = "print the current user"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            print("tv")
        }
    },
    "grep" to object : Command {
        override val help = "usage: grep [pattern] [file ...]"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            if (argv.size > 1) {
                files.forEach { file ->
                    if (file.name == argv[1]) {
                        file.content.lines().forEach {
                            if (it.contains(argv[0])) {
                                print(it)
                            }
                        }
                        return
                    }
                }
                print("${argv[1]}: no such file")
            }
            else {
                print(help)
            }
        }
    },
    "exit" to object : Command {
        override val help = "exit the shell"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            window.close()
        }
    },


    "ai" to Console.AI,
    "cv" to object : Command {
        override val help = "shortcut to open my CV"
        override fun complete(argv: List<String>): List<String> = listOf()
        override fun exec(argv: List<String>, print: (String) -> Unit) =
            window.open("https://tiulp.in/cv.pdf", blank)!!.focus()
    },
)