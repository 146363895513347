data class File(
    val name: String,
    val content: String,
    val owner: String = "tv",
    val size: Int = content.length,
    val date: String = "1970-01-01",
    val permissions: String = "rw-r--r--",
)

val jobs = File("jobs.log", """
    [3]   ${b("Running")}    05.2022-present    Software & Infrastructure Engineer at JetBrains N.V. $br
    [2]   ${b("Stopped")}    07.2020-02.2022    Software Engineer at IntelliJ Labs Co Ltd. $br
    [1]   ${b("Stopped")}    02.2019-06.2020    Junior Researcher at Center of Reactive Programming $br

""".trimIndent())

val hey = File(
    "hey.html", """
                👋 Hi, I'm ${b("Viktor Tiulpin")} (${h("@tiulpin", "https://x.com/tiulpin")}), an
                engineer passionate about Computer Science. $br$br

                ${h("Notes", "https://v.$host/")}
                · ${h("CV", "https://$host/cv.pdf")} 
                · ${h("LinkedIn", "https://linkedin.com/in/tiulpin")}
                · ${h("GitHub", "https://github.com/tiulpin")}$br$br

                Now I work on tools for developers at ${h("JetBrains", "https://jetbrains.com/")}.
                We make ${h("AI Platform", "https://www.jetbrains.com/ai/")} and ${h("Qodana", "https://jetbrains.com/qodana")}. Also, I ${h("maintain", "https://github.com/tiulpin")} OSS
                projects.$br$br
                        
                ${b("P.S.")} This website is ${h("powered by Kotlin/JS", "https://github.com/tiulpin/kotlin-cv.js")} and you can chat with AI here!$br
    """.trimIndent()
)
val pubkeyTxt = File(
    "pubkey.txt", """
-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBGCEJiYBEADHP3EtYZcvTyct+zzMM1JKp4rdOq4V8FT0UqgYcTQ7R35Qm5t2
S2tVEGqq7w8FATF8VCyUy9WrUeOuUZLkkOsstamuHcxrErzCmYwzoyUKnpDl3X29
a99wcYZW2klmuzZdEgd0KaOGQ5+9DarP6nK9ipKqFMkE86n/lB7OPgGpL6nB/SDi
h9nYKDfEX1ovh/nIF9ZJ6ce2qUZ2QvBSkPzGLZ4JApYUr+qCUacqXY+vpIBbv4WC
7AHm+g5+0iF/hdYyE4ExOlY5Xo/iOgxStwWChsOELWiitCG1rYfGpVZTQ9Doelik
94DRKp9HNFxOa4XC5wfFlKl7GsDCSdTBuTg+IwL6e22kXKdx3TDPCKjibKGx782p
ydBGrSZ5T4ydnzEUAlpeq4B83rjaaHs/ihSC0GIHTCWbCexAibWGdU8cW8btcnQP
oSWc7EgZgM6KRkueEPonqhvm741AGyR9SDeyZJPAAxvXrR6dYh99XqixI8AVlYxl
6I88p0lCnWzkS/fKHMr3vNPRNf0AoTVtFS62LaBh/W6WaTRBSv9MToNtcR80ziWq
In0wlyt0oi5BzJt1Vqwm7wHXVCfYTvnJEBeHT19gwFGQmogkSCyW1Z3+558XPtoL
gUFflHmUDUTJ1brH6ANraqrueZ7/ySNdgHenQBB1uA9+zPwuH7PO4bAcBwARAQAB
tCBWaWt0b3IgVGl1bHBpbiA8dmlrdG9yQHRpdWxwLmluPokCVAQTAQgAPhYhBCWt
CSiNFi/N/H3iXg5cx7R6LRoqBQJghCYmAhsDBQkFo5qABQsJCAcCBhUKCQgLAgQW
AgMBAh4BAheAAAoJEA5cx7R6LRoqs4IP/2jyYzLznUxj9c4c/FwWqEoOhqBlUDIc
Wm8ZcieDorsjdkzSxE/5JoSjwZNwjTeb3ZwrwNhu1kcJQp3NOx7f0nJEC7K1KKHe
gyrT8masN2cJREpT8mt2xhoNtOjyG2ISY3ir45ejMSuMCaBWhOPr8M7SEPnZd5VO
nqctNskPVzHmpQssmBJDkoL+SeLQMcfJu5/jMkmvL0tMHCFDkrqTtiOVUB8ov+5W
wBVcTa4ehdX8+8dyN8rDoS6QcWDjsyiVT9wnttM6zmPzh4UkVdxQgykwcwHxfLYS
BBGhVG+2Ixy9YvcMao9s4nXvBNkq351itxFRi1JywqtEqQz3LpejdvCiZLbo6ut6
GrTym+aXh+UJNrBg2ItPi7CdLLpAlg+HzSg3WKAYMFokL2wHD930WoKWfQ9dUlRZ
Ef0ksxKhK3iAfsijgGEvGL3mXQfDYO+B7cJOSlDE8NdGkqiU710ZnWToaq0XH7JZ
sBMzn/cwkUijo8z1awOfsFa2yhD5EPvc+GJPf0PjEDqANIfHMJMQzw+ROsN68vSi
Dguk5ihpsvVpIHefUQQB31wY0e1mexfuC1+mEbUJGZrPNVoqPrTuOidL0Dd1x7m8
UQPe8DgSTmCypd/S4lpzksVRQVbBac6fhWh09cuxN4uX0tt1WJWpwbxbAMZxqPLc
ky+GTU6x4UrkuQINBGCEJiYBEAC67cJXQ1nuqcdBUqNv6dWdX0taOfmoeH3UkA10
Wh+XNMrQaSv9zYZWZdKJCkfkWhW7JSycerZMsS7XyA7Vl/ZtocYqoTERKiU0h1I4
h9d9r0Q1ZQzLfa3UbXWpjbCe4yTWfDYpFhGMzQMGI8c4jjlpjOxaV1uzTkvDx4Rg
ZRXnct0sUvMGEeb4c5OC9ItyEHYv85A45939M6fqwzve8Mf34R2EwjKodKP8kQqW
SSzx+bP/BGn7wQ14PTgH8VWS3CG1R3+yVEYoDjuD2X/5mccnIImMw++6dJdknqmD
tNBLbIgDmOipHSTK8Go8OvO6isKlwhyuM3cSO/ov60i8lSlE2q75UI2wIACa+F+8
lEEiF5zHY2//zXs2Q6hqwpPd9rs/IaaQWsdKZ5YbgbsGwalinPSzhNSvRHWQVFAH
5VCmHixVXXaFun1LbSGcwAM1o+llEzDWK31nfq0V6T9yy4qClDAFE3D/ZsnPuj+t
5feuKwZzHrqvdoFwbqaPYyJAs3bKeaY01h7OGQC21aTpJqJzMPOpUEQTrSTTjtiX
F45tqugG97qIS1xGSqA3d6oOWo5AbBs1+1iUHbzNWHu7Mlu8SthY4lwc+38LuR96
ZQjwKfVsTY3M9XZpoeMY3wgQbhredbLGrdlUlhZ0Xms6UqpZT7hnnQ7p4q/MPRga
h5OMbQARAQABiQI8BBgBCAAmFiEEJa0JKI0WL838feJeDlzHtHotGioFAmCEJiYC
GyAFCQWjmoAACgkQDlzHtHotGiqErhAAsd3ToGPbE83cGCHbjQL0dRQ+H1n3wtH9
y9hdmn66lXSAhKK3B7fX33yE7/g+qRGnHHggSqHxvrpzKDHvgWbs2v8Icq7M3tgk
qQa0FYBXP4zC4emE9mUCzfS0DeEMnYEu0bgdvqLGX4UoygggAjhfxg68fOkmejjp
vYU/O5bZPhMhv2Q4SNjm4RKQAUSK3hmxFbFkLqAO1/BwCpLjWaJYfmWmBN/LpDij
A/Y61AA8uu4AAU9WR0n8V1za/+l1MJmpofwH97+gTYp9i/t6JE2+wvskY8rQgBJk
CpGL+R+qADpi1v2COpR+yZgzzyls18CIV/NUsknoJEgz/pNDviKG/hw5D2LwGSCo
+jHaUSmPbUTKwIHbBwPSjocpjVRqfbOpxiOn24jBWbzlgrCi0YMub7XzHnkOMnro
EGF3i8MZeu5sl4Kb2Kj7jpUUDxKpo+jioBJCwXrrVlQYos8V/VRFCpXgjlDKYq+Z
nfDbx8BI7pC+iOmcHenbkL3ki6+CaVOC4HPW/ZqefYhkOjlnYePMg3I50YAtdae7
6tXk5EsKvvPylcH5PeUKydTwkRbVCtIHEw9/fw1fnVmqVt/BlTvkutHaiBy4IQvs
oPEjPojA/gqtmBhAlGCYZi+TbdDkEdoTV5+oa+BAbtJFQsfi3g0v9K1pk7HaCgHO
wsyebcQh/Ky5Ag0EYIQmJgEQAOfAPV1R7d2HCc6GchazDLd+DrJnmqnikGxdv/44
JVz1zDooLMcX5v1GuVs6AaniUDGQTOROGKyvbLma6KLRQjfa4PUkBSFT/LX4/YgG
tBT4GLWe2316OVOnyYs1Ua4U88PkJHF6wX9GajZK5YMi3N8V0O2AsrfHTbgDSzfm
GYLsj/FCxcHYpMhZQLX8Mly0fLQnO+NtwUVzzriBohminX06b2OPe6wJ6pjVzNTk
K66cBSYqYaD88ZUtuyLb4oK204Bj8tktu/w8KHjSrOjiSsklWdugPxkV3/bLj7gp
0BamPYG29D1DlcrASp6l94wKb/sSO+eQxWOUUyLwELfu+gqw9XNerA7rZuAwbKXi
rFrhy2XgujU13KWDjWK8J/6+hUv+pg/S+Eam4nvK6amvg0PvgPh/rPtnJL0Do/Qs
z0IofHzn8ok/k3qflyd+GUna4LmuZD6CGGs6gPiHqWWrVZiuKW2IM212zjcTO9lf
9PEES6skHs/PoW0fjgZwzqHzAQVMrCDjjJjt6Vm39wpHraGNeiE9DLxrae/98i3o
5YNaHGS4aBSZ/cHRBz+5FHGfsjeVuDdRtfobusRXUDfMRh4fDldwbCYwDNDmvD/Z
IgWQNnlHpQXkwi6tvS405QRPOcL5Z1gZ+Qr0rPF7bA1e7K004aUjucHJMw5jqHeM
Vyp7ABEBAAGJAjwEGAEIACYWIQQlrQkojRYvzfx94l4OXMe0ei0aKgUCYIQmJgIb
DAUJBaOagAAKCRAOXMe0ei0aKgVZD/wJD5Pd2rbdKxxXoi+JbUQS7TMkVcch18XC
nWL/BNZ95DqCoZSDKFpqp6swx9eLMu6S5GhJ+9s39jtbEvfvuVPRzZ/MenuM+o16
qLTy+i0A3m1GTiT1IRhSj2OfnN5CDq9pHllJl+Q0V20jTGpSFwGc1Fe0bKY9SZDn
IOfz1I4wVyS6jgyPNn8oaCVeOTFAvPsrKPPvZrba8LuZ8Bn0BBsQdUIJWaVB7I0o
hhqu266uKJGzr3sPb8cWdPBbDGvB0J7xzUkZLZum1Ob77fost/w2JDqtUfz4jJeJ
dvfrlWJwqha1QIpn6sJhBbbtIn4UMzVxssCgFMr+XlGVdHpPgbdGDoM4kyGgV5OG
RqaP2OJhWk0+41UMKxkJtXsMj5j/VH7vTtCjT+9sqlCeNCD+frG0GRSVHTyfPmYO
FvhD4oYZ+eBJ8VZIihvWPkrW9ItMYuFE7fo/hUfTry+JbNa9ht4/rxQzSePiS2nb
c7+hdqADH18CwnvdTVtZv9AG30f46nAEFia+W+hXIDtA+5T0XYi6UDqAYB48/poO
yU/ThJzbHj95ELIGd98s/YgdCfrJ+hgGGho954P2dKqXe3GExyNDJOSvgrqygeEP
rNpKcy5cY2bGDN5S9SKuFIB+SwWEKe2z1BtWz69Q1Netkq6Mar/c3czawclniFtj
pzGJ+z50IA==
=oBIa
$br-----END PGP PUBLIC KEY BLOCK----- 
    """.trimIndent()
)
val talk = File(
    "talk",
    """
                Want to talk about something? Go to ${h("calendly.com/tiulpin/talk", "https://calendly.com/tiulpin/talk")} 
                and pick the timeslot. $br$br
                Also, you can always drop me a message on ${h("Telegram", "https://t.me/tiulpin")}.
    """.trimIndent(),
)
val quoteMd = File(
    "quote.md", """
                "A human being should be able to change a diaper, plan an invasion, butcher a hog, conn a ship,
                design a building, write a sonnet, balance accounts, build a wall, set a bone, comfort the dying,
                take orders, give orders, cooperate, act alone, solve equations, analyze a new problem,
                pitch manure, program a computer, cook a tasty meal, fight efficiently, die gallantly.
                <a>Specialization is for insects.</a>"$br$br ― Robert A. Heinlein.
    """.trimIndent()
)

val files = listOf(
    hey,
    jobs,
    talk,
    quoteMd,
    pubkeyTxt,
)
