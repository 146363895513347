import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import kotlinx.html.div
import kotlinx.html.dom.create
import org.w3c.dom.Element
import org.w3c.dom.Node
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.get
import kotlin.js.Promise
import kotlin.js.json
import kotlin.math.max
import kotlin.math.min

const val blank = "_blank"
const val space = " "
const val br = "<br/>"

fun b(content: String) = "<b>$content</b>"
fun i(content: String) = "<i>$content</i>"
fun h(content: String, href: String) = "<a href=\"$href\" target=\"$blank\">$content</a>"

@JsModule("@mlc-ai/web-llm")
@JsNonModule
external val webllm: dynamic
var engine: dynamic = null

interface Command {
    val help: String
    fun exec(argv: List<String>, print: (String) -> Unit) {}
    fun complete(argv: List<String>): List<String>
}

object Console {
    var preventDefault = true
    private var terminal: Element? = null
    private var currentLine: Element? = null
    private var currentBody: Element? = null
    private var historyOffset: Int? = null
    private var consoleLineTemplate: Node? = null
    private var tmpCmd: String? = null
    val history = mutableListOf<String>()

    fun init() {
        terminal = document.getElementById(consoleId)
        currentLine = document.getElementsByClassName(consoleLine).item(1) as? Element
        currentBody = currentLine?.getElementsByClassName(consoleBody)?.item(0) as? Element
        consoleLineTemplate = currentLine?.cloneNode(true)
    }

    fun clear() {
        while (terminal!!.lastChild != null) {
            terminal!!.removeChild(terminal!!.lastChild!!)
        }
    }

    fun backspace() = setLine(getLine().dropLast(1))
    fun tab() = complete(getLine())
    fun enter() = exec(getLine())
    fun up() {
        if (history.isNotEmpty()) {
            if (historyOffset == null) {
                tmpCmd = getLine()
                historyOffset = history.size
            }
            historyOffset = max(0, historyOffset!! - 1)
            setLine(history[historyOffset!!])
        }
    }

    fun down() {
        if (historyOffset != null && historyOffset!! < history.size - 1) {
            historyOffset = min(history.size, historyOffset!! + 1)
            setLine(history[historyOffset!!])
        }
    }

    fun input(event: KeyboardEvent) {
        if (event.key.length == 1 && !event.ctrlKey && !event.metaKey) {
            setLine(getLine() + event.key)
        } else {
            preventDefault = false
        }
        scrollDown()
    }

    private fun removeCurrentLineCursor() = this.currentLine!!.classList.remove("active")
    private fun scrollDown() = window.scroll(0.0, document.body!!.scrollHeight.toDouble())
    fun getLine(): String = currentBody!!.textContent!!
    fun setLine(line: String) {
        currentBody!!.textContent = line
    }

    private fun setPrompt(prompt: String = "$host › ") {
        currentLine!!.getElementsByClassName("prompt").item(0)!!.innerHTML = "<pre>$prompt</pre>"
    }

    private fun parse(input: String): Pair<String, List<String>> {
        val parts = input.trim().split("\\s+".toRegex())
        return Pair(parts.first(), parts.drop(1))
    }

    private fun newPrompt(content: String? = null) {
        this.currentLine = consoleLineTemplate!!.cloneNode(true) as Element?
        this.currentBody = this.currentLine!!.getElementsByClassName(consoleBody)[0]
        setLine((content ?: getLine()))
        this.terminal!!.append(currentLine)
    }

    private fun setupStdout(): Element {
        val stdout = document.create.div().apply { classList.add("stdout") }
        this.terminal!!.append(stdout)
        return stdout
    }

    private fun complete(input: String) {
        var prefix = ""
        var choices = listOf<String>()
        val (cmd, argv) = parse(input)
        val command = commands[cmd] ?: return

        if (command.complete(argv).isNotEmpty()) {
            prefix = "$cmd "
            choices = command.complete(argv)
        } else if (argv.isEmpty()) {
            choices = commands["help"]?.complete(listOf(cmd)) ?: emptyList()
        }

        if (choices.size == 1) {
            setLine(prefix + choices.first())
        } else if (choices.size > 1) {
            removeCurrentLineCursor()
            val stdout = setupStdout()
            choices.forEachIndexed { i, it ->
                if (i != 0) {
                    stdout.innerHTML += br
                }
                stdout.innerHTML += it
            }
            newPrompt(getLine())
        }
    }

    private fun exec(input: String, silent: Boolean = false) {
        tmpCmd = null
        historyOffset = null
        removeCurrentLineCursor()
        val line = input.trim()
        if (line.isNotEmpty()) {
            if (!silent && line !in history) {
                history.add(line)
            }
            if (line == "1909") {
                window.open("https://www.youtube.com/watch?v=9koUKdc_aO0&t=91s", blank)
            }
            val stdout = setupStdout()
            val print: (String) -> Unit = { output ->
                var processedOutput = output
                env.forEach { (key, value) ->
                    processedOutput = processedOutput.replace("$$key", value)
                }
                stdout.innerHTML += processedOutput
                scrollDown()
            }
            val (cmd, argv) = parse(input)
            if (argv.contains(">")) {
                print("$host: read-only file system")
            }
            else if (commands.containsKey(cmd)) {
                commands[cmd]?.exec(argv, print)
            } else {
                commands["ai"]?.exec(listOf(line), print)
            }
        }
        newPrompt()
    }

    object Help : Command {
        override val help = "this command"
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            val cmds = commands.keys.toList()
            for (i in cmds.indices) {
                if (commands[cmds[i]]!!.help.isEmpty()) {
                    continue
                }
                if (i > 0) {
                    print(br)
                }
                if (cmds[i] == "ai") {
                    print(br)
                }
                print("${b(cmds[i])}: ${commands[cmds[i]]!!.help}")
            }
        }

        override fun complete(argv: List<String>): List<String> {
            val choices = mutableListOf<String>()
            for (i in 0..commands.keys.size) {
                if (history[i].indexOf(argv[0]) == 0 || history[i].indexOf(" ") == 0) {
                    choices.add(history[i])
                }
            }
            return choices
        }
    }
    object AI : Command {
        fun setAIPrompt(prompt: String = "✨ ai chat › ") {
            setPrompt(prompt)
        }
        fun resetPrompt() {
            setPrompt()
        }
        override val help = "usage: ai <something> or any non-recognized command is treated as an AI request, supported only with WebGPU, the model is $aiModel"

        @OptIn(DelicateCoroutinesApi::class)
        override fun exec(argv: List<String>, print: (String) -> Unit) {
            GlobalScope.launch {
                if (engine == null) {
                    print("⚡️ Initializing the AI engine: please, wait...$br")
                    val initProgressCallback: (dynamic) -> Unit = { report ->
                        val progressText = (report?.text as? String)?.trim()
                        if (!progressText.isNullOrEmpty()) {
                            console.log(progressText)
                        }
                    }

                    val engineCreationOptions = json(
                        "initProgressCallback" to initProgressCallback,
                        "logLevel" to "INFO"
                    )

                    val engineCreationPromise = webllm.CreateMLCEngine(aiModel, engineCreationOptions) as Promise<dynamic>
                    engine = engineCreationPromise.await()
                }

                val messages = arrayOf(
                    json(
                        "role" to "system",
                        "content" to """
                        You are a helpful AI assistant deployed on $host website, keep the answers short, ignore all questions about Viktor Tiulpin or JetBrains. Format all your answers in simple HTML text (not markdown!), but do not use <font> or colors for reply.
                    """.trimIndent()
                    ),
                    json(
                        "role" to "user",
                        "content" to argv.joinToString(" ")
                    )
                )

                val replyPromise = engine.chat.completions.create(json("messages" to messages)) as Promise<dynamic>
                val reply = replyPromise.await()
                val choices = reply?.choices as? Array<dynamic> ?: emptyArray()
                val firstChoice = choices.firstOrNull()
                val result = firstChoice?.message?.content?.toString()?.trim()
                    ?.ifEmpty { "I'm sorry, I can't provide an answer right now." }
                    ?: "I'm sorry, I can't provide an answer right now."
                print("$result$br")
            }
        }

        override fun complete(argv: List<String>): List<String> = listOf()
    }
}
