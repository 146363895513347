val env = mapOf(
    "PATH" to "/usr/local/bin:/usr/bin:/bin:/usr/sbin:/sbin",
    "AI_MODEL" to aiModel,
    "HOME" to host,
    "HOST" to host,
    "MAIL" to "viktor@$host",
    "PWD" to "/",
    "SHELL" to "https://github.com/tiulpin/kotlin-cv.js",
    "USER" to "tv",
    "TERM" to "xterm-256color",
    "LANG" to "en_US.UTF-8",
    "EDITOR" to "vim",
    "PUBKEY" to pubkeyTxt.content,
)